body {
  height: 100vh;
  padding: 20px;
  text-align: center;
  overflow: hidden;
}

#root {
  height: 100%;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ant-form-item {
  justify-content: space-between;
}
